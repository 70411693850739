<template>
    <app-file-upload
        v-model="valueLocal"
        :label="field.titleSingular"
        :filter="filter"
        :is-readonly="isReadonly"
        :is-dense="isDense"
        :is-disabled="isDisabled"
        :is-delete-disabled="true" />
</template>

<script>
export default {
    components: {
        appFileUpload: () => import('@/components/AppFileUpload')
    },
    props: {
        field: {
            type: Object,
            default: null,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        value: {
            type: String,
            default: null,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
